<template>
  <section class="service_m">
    <img
      class="banner"
      style="width: 100%; height: 100%"
      :src="$t('locale') == 'zh' ? banner : banner_en"
      alt=""
    />
    <div class="service_tabs">
      <img
        style="width: 100%; height: 100%;padding-top:20px;padding-bottom:20px;"
        :src="$t('locale') == 'zh' ? agendas : agendas_en"
        alt=""
      />
    </div>
    <FooterB></FooterB>
  </section>
</template>

<script>
import FooterB from "./components/FooterB";
export default {
  components: {
    FooterB,
  },
  data() {
    return {
      banner: require("../../assets/zh/images-mobile/bannerac.png"),
      banner_en: require("../../assets/en/images-mobile/bannerac_en.png"),

      agendas: require("../../assets/zh/images-mobile/allac.png"),
      agendas_en: require("../../assets/en/images-mobile/allac_en.png"),
    };
  },
  mounted() {
    this.swiperDemo();
  },
  methods: {
    swiperDemo() {
      var swiper = new Swiper(".swiper-container2", {
        autoplay: false,
        allowTouchMove: true,
        preloadImages: false,
        centeredSlides: false,
        spaceBetween: 16,
        slidesOffsetBefore: 16,
        loop: true,
        slidesPerView: "auto",
      });
      window.onresize = function () {
        swiper.update();
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.service_m {
  margin-top: 48px;
  word-break: break-word !important;

  .banner {
    width: 100%;
    height: 150px;
  }

  .service_tabs {
  background: #fff;
  }
}
</style>
